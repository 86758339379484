<template lang="pug">
  article.olfactory.fixed.overlay.bg-navy-deep.text-white.flex
    template(v-if="doc")
      .w-full.overflow-y-scroll.scrollbars-hidden
        hero-linn-text.olfatext(ref="text")
          p(v-for="par in doc.data.animating_text")
            span(v-for="word in par.text.split(' ')", v-html="word + ' '")
        resp-img.pointer-events-none.transition-opacity(ref="bg", :bg="true", :image="doc.data.overlay_image", :style="{opacity: imgOpacity}", style="transition: opacity 1200ms")
        img.hidden(src="fail", @error="runAnimationSequence")

      footer-links.absolute.bottom-0.left-0.transition-opacity.duration-1000.pt-10.lg_pt-20.px-8.lg_px-20(:class="{'opacity-0 pointer-events-none': !footerVisible}")
        a.p-3.-m-3(:href="doc.data.buy_link.url", :target="doc.data.buy_link.target", @mouseenter="showImage", @mouseleave="hideImage") Buy
</template>

<script>
import HeroLinnText from '@/components/HeroLinnText'
import { wait } from '@/views/Home'
export default {
  name: 'Olfactory',
  components: { HeroLinnText },
  data () {
    return {
      words: null,
      speed: 15,
      imgOpacity: 0.3,
      anim: null,
      footerVisible: false
    }
  },
  computed: {
    doc () {
      return this.$store.state.docs.find(doc => doc.type === 'olfactory')
    }
  },
  created () {
    this.$store.commit('setUIColor', 'white')
  },
  methods: {
    animate (after) {
      // animate words first
      if (!this.words) {
        this.words = Array.from(this.$refs.text.$el.querySelectorAll('span'))
      }
      if (this.words.length) {
        // reveal keyword
        this.words[0].style.opacity = '1'
        // remove keyword
        this.words = this.words.slice(1)
        // recursive animation...
        this.anim = setTimeout(() => this.animate(after), this.speed)
        return
      }
      return after()
    },
    async runAnimationSequence () {
      try {
        await wait(1200)
        this.animate(async () => {
          await wait(600)
          this.footerVisible = true
          this.bindMenu()
        })
      } catch (e) {
        console.error(e)
      }
    },
    showImage () {
      this.imgOpacity = 1
    },
    hideImage () {
      this.imgOpacity = 0.3
    },
    bindMenu (bind = true) {
      const menu = document.getElementById('app-header')
      if (menu) {
        if (bind) {
          menu.addEventListener('mouseenter', this.showImage)
          menu.addEventListener('mouseleave', this.hideImage)
        } else {
          console.log('unbind')
          menu.removeEventListener('mouseenter', this.showImage)
          menu.removeEventListener('mouseleave', this.hideImage)
        }
      }
    }
  },
  destroyed () {
    clearTimeout(this.anim)
    this.bindMenu(false)
  }
}
</script>

<style>
.olfatext{
  & span{
    opacity:0;
    transition: opacity 300ms;
  }
}
</style>
